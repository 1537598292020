import React from "react";
import './benefits.scss';

import benefits1 from "../../assets/images/benefits/1.avif";
import benefits2 from "../../assets/images/benefits/2.png";
import benefits3 from "../../assets/images/benefits/3.avif";
import benefits4 from "../../assets/images/benefits/4.png";
import benefits5 from "../../assets/images/benefits/5.avif";
import benefits6 from "../../assets/images/benefits/6.avif";

import useMagicCards from "../magicCards";

const Benefits = () => {
  const magicCardsRef = useMagicCards();

  return (
    <div className="benefits-container" >

      <div className="benefits-title" loading="lazy">
        <h2 className="animated">Get the agency-grade product without the $50k price tag</h2>
        <p>Relentless focus, fast execution, and a commitment to the quality of craft.</p>
      </div>

      {/* Attach the ref to this container */}
      <div className="grid-container_benefits" ref={magicCardsRef} >

        <div className="benefit-item animated magic-card">
          <img src={benefits1} alt="Strategic Art Direction" loading="lazy" />
          
            <h3>Strategic Art Direction</h3>
            <p>Every pixel is strategically designed to turn visitors into customers. Our focus isn't just on the great looks, but on improving your KPIs.</p>
         
        </div>

        <div className="benefit-item animated magic-card">
          <img src={benefits2} alt="Strategic Art Direction" loading="lazy" />
          
            <h3>Dedicated focus</h3>
            <p>I only take on 3 new clients per month. Why? Quality over quantity. Your project deserves my full attention, not whatever's left after juggling 20 others.</p>
         
        </div>

        <div className="benefit-item animated magic-card">
          <img src={benefits3} alt="Strategic Art Direction" loading="lazy" />
          
            <h3>Industry experience</h3>
            <p>From MVP to IPO, we design success, convincing investors and crushing your competition. Your tech deserves better than your intern's Figma skills.</p>
         
        </div>

        <div className="benefit-item animated magic-card">
          <img src={benefits4} alt="Strategic Art Direction" loading="lazy" />
          
            <h3>Growth-focused approach</h3>
            <p>Clients see an average 40% increase in conversions and have secured over $100M in funding after working with us.</p>
         
        </div>

        <div className="benefit-item animated magic-card">
          <img src={benefits5} alt="Strategic Art Direction" loading="lazy" />
          
            <h3>Premium Design</h3>
            <p>Stand out with a brand that tells your unique story.</p>
         
        </div>

        <div className="benefit-item animated magic-card">
          <img src={benefits6} alt="Strategic Art Direction" loading="lazy" />
          
            <h3>Rapid pace</h3>
            <p>Let's turn a 'meh' into 'holy shit' in 90 days or less. Elevate your brand and user experience to match the quality of your technology.</p>
         
        </div>

     

      </div>
    </div>
  );
};

export default Benefits;
