import React, { useState, useEffect } from 'react'
import './roast.scss';

import roast1 from './roast1.png';
import roast2 from './roast2.png';
import roast3 from './roast3.png';
import roast4 from './roast4.png';

import natan from '../../assets/images/legends/natan.jpg';
import matias from '../../assets/images/legends/matias.jpg';
import chris from '../../assets/images/legends/chris.jpg';
  


const Roast = () => {

  const link = "https://buymeacoffee.com/roccostudio/e/337417";
  
  const images = [
    roast1,
    roast2,
    roast3,
    roast4,
  ];
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Add useEffect for image rotation
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="roast-container">
      <h1>Is your hero section holding back your website conversions?</h1>
      <p>We'll analyze, reimagine, and redesign your hero section, providing actionable design enhancements to drive higher conversions.</p>
      <a href={link}><button>Roast me for just $9 <span>+ Get Figma File</span></button></a>
      <p id="limited-time-offer">Limited time offer</p>
      <div className="social-proof">
          <img src={natan} alt="Natan" />
          <img src={matias} alt="Matias" />
          <img src={chris} alt="Chris" />
          <p>Proudly roasted 20+ websites</p>  
      </div>

      <div className="roasted-gallery">
          <img 
            src={images[currentImageIndex]} 
            alt={`Roasted website example ${currentImageIndex + 1}`} 
          />
      </div>
    </div>
  )
}

export default Roast