import React from 'react'
import './caseStudy.scss';

const CaseStudy = ({title, role, deliverables, what, why, how, children}) => {
  return (
    <div className="case-study-main-container">
        <h2 className="case-study-title">{title}</h2>
        <div className="two-column-metadata">
          <span className="quote-case-study">
            — My role: <br />
            {role}
          </span>
          <span className="quote-case-study">
            — Deliverables: <br />
            {deliverables}
          </span>
        </div>
        <div className="block">
          <h3>The What</h3>
          <p>
            {what}
          </p>
        </div>
        <div className="block">
          <h3>The Why</h3>
          <p>
           {why}
          </p>
        </div>
        <div className="block">
          <h3>The How</h3>
          <p>
          {how}
          </p>
        </div>
        {children}

    </div>

  )
}

export default CaseStudy