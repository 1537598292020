import React from "react";
import './reviews.scss';
import Stefan from "./stefan.jpg";

const Reviews = () => {
  const kText = (
    <>
      It's been great working with Roco. He was able to capture the details that
      I needed in order to have a great design for my SaaS application. He was
      able to do this despite a possible lack of information on my end so I'm
      happy with the way things have turned out when we were working on the
      landing page. With his great work ethic, I highly recommend working with
      him. For sure, if there are many other things that I want to add in my
      SaaS application, I will contact him.
    </>
  );

  const vsText = (
    <>
      I hired Roco for a project that involved designing a custom logo and a
      one-page WordPress website for my private security company, and{" "}
      <b>he did an outstanding job!</b> Roco is an incredibly talented creative
      designer <b>who delivered exactly what I was looking for, and more.</b> He
      was able to take my vision and turn it into a{" "}
      <b>beautiful, professional-looking logo and website</b> that perfectly
      represented my brand. Throughout the process, Roco was{" "}
      <b>responsive, communicative, and professional.</b> He delivered on time
      and within budget, and I couldn't be happier with the end result. I would
      highly recommend Roco to anyone in need of a top-notch creative designer,
      and I look forward to working with him again in the future.
    </>
  );

  const sgText = (
    <>
      Perfect, with Roco, I feel safe in different projects as he is a{" "}
      <b>reliable, patient, and super communicator.</b> His design skills and
      customer-centric approach mean a lot to me. Having him as an external team
      member is a blessing. <b>Truly recommend him for any project.</b>
    </>
  );

  const vmText = (
    <>
      Do not miss Roco. <b>Super service</b>, understanding agile development,{" "}
      <b>client-oriented</b>, fast in communication, and{" "}
      <b>committed to the project</b>. We just finished the first sprint, now
      ordering the 2nd. Also, he has a very fine taste in visual appearance. And
      at the same time, he has tech knowledge. An overall experience is that it
      is a <b>true pleasure working with Roco</b>.
    </>
  );

  const sgText2 = (
    <>
      <b>My life became easier when I started to work with Roco.</b> Patient,
      detailed, responsive, and creative. High level of professional
      communication with an agile and adaptive approach to the project.{" "}
      <b>Full recommendation.</b> We are still working on the project, on a
      sprint-by-sprint basis. Feeling comfortable to go further with the project
      as another part of my team is also very happy with the delivery too.
    </>
  );

  const dcText = (
    <>
      <b>Excellent service</b>, very attentive to details and responsive to all
      feedback on the project. The website design was of{" "}
      <b>great quality and very fast.</b>
    </>
  );

  const ioText = (
    <>
      I’m an interior designer, I was struggling with my social media accounts
      and my website I didn’t knew how to engage people or how to express what
      my company values were, and of course how to make it look and feel
      elegant, Roco <b>was amazing</b> after looking and asking a lot of people,
      he was the only one who <b>really understood me</b> and was able to help
      me with the needs that I had for my studio, he was so patient and was able
      to translate my ideas in the perfect way, he gave me all the tools to help
      my studio grow he created a whole guide to help me with in the future and
      i learned a lot, he did the website and the visual identity of my studio
      among other things, the <b>quality of his job</b> help a lot to my studio,
      i’m very <b>happy with the results</b>, look forward to work with him
      again.
    </>
  );

  const sText = (
    <>
      I highly recommend working with <b>@RocoPagella</b>. He's dedicated and
      wants to collaborate to make the best outcome! My SaaS Prices comparison
      website wouldn't be the same without his designs
    </>
  );

  const renderProfileImage = (hasImage, imageSrc, initials, bgColor) => {
    if (hasImage) {
      return <img src={imageSrc} alt="" className="quote-profile_image" />;
    }
    return (
      <div className="quote-circle" style={{ backgroundColor: bgColor }}>
        <p>{initials}</p>
      </div>
    );
  };

  return (
    <div className="reviews-container">
      <div className="reviews-title-container">
        <h2 className="animated">What Past Clients Say</h2>
        <p>Rated 5/5 by happy  partners</p>
      </div>
      
      
      <div className="quotes-container">

        <div className="quote animated">
          {" "}
          {/* VIRTUSEC */}
          <p>{vsText}</p>
          <div className="quote-profile_container">
            {renderProfileImage(false, "", "VS", "#247612")}

            <div className="quote-profile_text">
              <p className="quote-name">VirtuSec</p>
              <p>Cibersecurity Company</p>
            </div>
          </div>
        </div>

        <div className="quote animated">
          {" "}
          {/* KIRON */}
          <p>{kText}</p>
          <div className="quote-profile_container">
            {renderProfileImage(false, "", "VS", "#247612")}

            <div className="quote-profile_text">
              <p className="quote-name">Miguel Dagatan</p>
              <p>Founder of Kiron</p>
            </div>
          </div>
        </div>

        <div className="quote animated"> {/* DANIEL */}
          {" "}
          <p>{dcText}</p>
          <div className="quote-profile_container">
            {renderProfileImage(false, "", "DC", "#D30ABE")}
            <div className="quote-profile_text">
              <p className="quote-name">Daniel Chacana</p>
              <p>CEO of Meet Agency</p>
            </div>
          </div>
        </div>

        <div className="quote animated">
          {" "}
          {/* SERGEJ */}
          <p>{sgText}</p>
          <div className="quote-profile_container">
            {renderProfileImage(false, "", "SL", "#D55E97")}
            <div className="quote-profile_text">
              <p className="quote-name">Sergej Lugovic</p>
              <p>CEO of Vesela Motika</p>
            </div>
          </div>
        </div>
        <div className="quote animated">
          {" "}
          {/* VESELA MOTIKA */}
          <p>{vmText}</p>
          <div className="quote-profile_container">
            {renderProfileImage(false, "", "VM", "#367B59")}
            <div className="quote-profile_text">
              <p className="quote-name">Vesela Motika</p>
              <p>Biodigital Nutrient Phaarming Company</p>
            </div>
          </div>
        </div>

        <div className="quote animated">
          {" "}
          {/* SERGEJ */}
          <p>{sgText2}</p>
          <div className="quote-profile_container">
            {renderProfileImage(false, "", "SL", "#D55E97")}
            <div className="quote-profile_text">
              <p className="quote-name">Sergej Lugovic</p>
              <p>CEO of Vesela Motika</p>
            </div>
          </div>
        </div>



        <div className="quote animated">
          {" "}
          {/* IARA */}
          <p>{ioText}</p>
          <div className="quote-profile_container">
            {renderProfileImage(false, "", "IO", "#247612")}
            <div className="quote-profile_text">
              <p className="quote-name">Iara Ortega</p>
              <p>Interior Designer</p>
            </div>
          </div>


        </div>

        <div className="quote animated">
          {" "}
          {/* Stefan */}
          <p>{sText}</p>
          <div className="quote-profile_container">
            {renderProfileImage(true, Stefan, "", "#247612")}
            <div className="quote-profile_text">
              <p className="quote-name">Stefan Skorpen</p>
              <p>Founder of SaaS Prices</p>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Reviews;
