import React, { useState } from "react";
import './services.scss';

import designImage from "../../assets/images/services/design.svg";
import uiImage from "../../assets/images/services/UI.svg";
import websiteImage from "../../assets/images/services/website.svg";
import brandingImage from "../../assets/images/services/branding.svg";
import appImage from "../../assets/images/services/app.svg";
import mvpImage from "../../assets/images/services/mvp.svg";
import eyeImage from "../../assets/images/services/eye.svg";

import useMagicCards from "../magicCards";

const Services = () => {
  const magicCardsRef = useMagicCards();


  return (
    <>
      

      <div className="services-container">
        <div className="services-title-container">
          <img
            className="animated"
            src={eyeImage}
            alt="your vision, fully executed"
            loading="lazy"
          />
          <h2 className="animated">Your vision, fully executed.</h2>
          <p className="animated">Services to fuel your Startup growth. Make users and investors fall in love with your product.</p>
        </div>

        <div className="services-item-container" ref={magicCardsRef}>
          

       

       

          <div className="service-item animated magic-card">
            <div>
              <h3>Website Development</h3>
              <p>
                Inspire trust, lead with confidence, and connect with your
                audience.
              </p>

            </div>

            <img src={websiteImage} alt="" />
          </div>

          <div className="service-item animated magic-card">
            <div>
              <h3>MVP Development</h3>
              <p>
                Transform your concept into a market-ready MVP in just two
                weeks.
              </p>
     
            </div>

            <img src={uiImage} alt="" />
          </div>

          <div className="service-item animated magic-card">
            <div>
              <h3>App Development</h3>
              <p>
                Your dream application, coded with purpose and built to impact.
              </p>
              
            </div>

            <img src={appImage} alt="" />
          </div>
        </div>

        <div className="services-more">
          <h3>and more...</h3>
          <div className="services-more-items">
            <p>Pitch Deck</p>
            <p>Landing Page</p>
            <p>Brand Design</p>
            <p>UI/UX Design</p>
          
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
