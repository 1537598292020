import React, { useRef, useEffect, useState } from "react";
import './hero.scss';
import roco from "../../assets/images/roco.jpg";

import matias from "../../assets/images/legends/matias.jpg";
import chris from "../../assets/images/legends/chris.jpg";
import natan from "../../assets/images/legends/natan.jpg";

import heroImg from "../../assets/images/herocover.png";
import Dialog from "../Dialog/Dialog";



const Hero = ({ title, subtitle, callendlyLink, gmailLink }) => {
  /* ########### h1 ANIMATION ################### */
  const textRef = useRef(null); // Create a ref for the rotating text element

  useEffect(() => {
    const roles = ["MVP", "SaaS", "app", "web"];
    const waitingTime = 3000;

    let roleIndex = 0;

    function displayRoleText() {
      const textElement = textRef.current; // Access the element through the ref
      if (!textElement) return; // Safeguard in case element is not found

      textElement.innerHTML = ""; // Clear the current text
      const currentRole = roles[roleIndex];

      currentRole.split("").forEach((char, index) => {
        const charSpan = document.createElement("span");
        charSpan.classList.add("char");
        charSpan.textContent = char === " " ? "\u00A0" : char; // Preserve spaces
        charSpan.style.animationDelay = `${index * 0.05}s`; // Stagger each letter by 0.05s

        textElement.appendChild(charSpan);
      });

      roleIndex = (roleIndex + 1) % roles.length; // Cycle through roles
    }

    const intervalId = setInterval(displayRoleText, waitingTime);
    displayRoleText(); // Initial call

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <>
      <div className="hero-container">
        <div className="hero">
          <div className="profile-hero-container">
            <img
              className="profile-img_hero"
              src={roco}
              alt="Roco Fernandez Pagella"
              loading="lazy"
            />

            <div className="profile-hero_text">
              <p>
                <b>Roco Fernandez Pagella</b>
              </p>
              <p>Software Engineer / Designer</p>
            </div>
          </div>

          <div className="h1-container_hero">
            <h1>
            Grow faster with a beautifully-crafted <span ref={textRef}></span> 
            </h1>
          </div>

          <p>A Product Design and Development studio that helps passionate Startup and SaaS Founders build the products of their dreams.</p>

          <div className="buttons-container_hero">
            <a href={callendlyLink} target="_blank" rel="noopener noreferrer">
              <button className="book-call_hero animated">Book a Call</button>
            </a>
            <a target="_blank" rel="noopener noreferrer">
              <Dialog buttonName={"Share your vision"} />
            </a>
          </div>

          <div id="hero-legends">
            <img src={matias} alt="" />
            <img src={chris} alt="" />
            <img src={natan} alt="" />
            <p>trusted by +25 legends</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
