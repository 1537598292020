import React from 'react'
import { useState } from 'react';
import './faq.scss';

const FAQ = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    const faqs = [
      { 
        question: "How does your process work?", 
        answer: "I begin by understanding your needs and goals, then create a detailed plan. I handle both design and development, progressing through structured stages—design, development, testing, and final launch—to ensure a seamless outcome." 
      },
      { 
        question: "What if I don't like the design or functionality?", 
        answer: "I collaborate closely with you throughout the process, whether it’s design or development, incorporating feedback early. If you’re not satisfied, I make revisions based on your input to ensure the final product aligns with your vision." 
      },
      { 
        question: "How long does it take to receive designs or prototypes?", 
        answer: "Timelines vary based on the project scope. I provide regular updates and prototypes, giving you a clear view of progress and allowing for timely feedback." 
      },
      { 
        question: "How do I share feedback during development?", 
        answer: "I work asynchronously for regular retainers and sprints. For larger projects, we can schedule weekly calls to discuss ongoing work, address technical requirements, and incorporate your feedback seamlessly." 
      },
      { 
        question: "Why wouldn’t I just hire a full-time designer or developer?", 
        answer: "Working with me provides flexibility to scale work up or down based on your project’s needs. I bring both design and development expertise, which can be more cost-effective than hiring separate roles." 
      },
      { 
        question: "Can you handle complex development tasks?", 
        answer: "Absolutely. I have experience with a range of development projects, including web apps, custom components, and integrations. I ensure that both the design and code meet high standards." 
      },
      { 
        question: "Do you offer maintenance and support after launch?", 
        answer: "Yes, I provide post-launch support for both design updates and development maintenance. Retainer options are available to keep your project running smoothly over time." 
      },
      {
        question: "What's your tech stack?",
        answer: "I primarily use React and Next.js for front-end development, with Node.js for back-end work and MongoDB, Supabase and Firebase for databases. For styling, I prefer Sass and sometimes leverage CSS frameworks as needed. I handle animations with GSAP and Lottie, and manage user authentication and data storage with Firebase or Supabase. I also integrate Stripe for payment processing and use GitHub for version control. This stack allows me to build responsive, scalable, and maintainable applications tailored to your project’s requirements."
      }
      
      
      
    ];
    
  return (
   <div className="faq-section">
      <h2>You got questions?</h2>
      <div className="faq-items">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              {faq.question}
              <span className="toggle-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className="faq-answer">
              {activeIndex === index && <p>{faq.answer}</p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FAQ