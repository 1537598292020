import React from 'react'
import './logos.scss';
import logo1 from "../../assets/images/logos/1.avif";
import logo2 from "../../assets/images/logos/highInvest.svg";
import logo3 from "../../assets/images/logos/saasPrices.svg";
import logo4 from "../../assets/images/logos/kiron.svg";
import logo5 from "../../assets/images/logos/evolv.svg";
import logo6 from "../../assets/images/logos/fireNotes.svg";
import logo7 from "../../assets/images/logos/obliqua.svg";


const Logos = () => {
  const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7];
  
  return (
    <>
     <div className="logos">
      <div className="logos-container">
        {/* Multiple sets of logos for smoother infinite scrolling */}
        {[...Array(4)].map((_, i) => (
          <div key={`set-${i}`} className="logos-set ">
            {logos.map((logo, index) => (
              <img key={`logo-${i}-${index}`} src={logo} alt={`Logo ${index + 1}`} />
            ))}
          </div>
        ))}
      </div>
    </div>
    </>
   
  )
}

export default Logos