import React from 'react'
import Dialog from '../Dialog/Dialog';
import './cta.scss';

const Cta = ({callendlyLink, gmailLink}) => {


  const x = "https://x.com/byrocostudio";
  const linkedIn = "https://www.linkedin.com/in/roco-fernandez-pagella/";
  const insagram = "https://www.instagram.com/roc_kstark/";


  return (
    <>
        <div className="cta-container" id="contact">

            <h2 id="contact-anchor" className="animated">Because your vision matters. Let's build it the right way.</h2>
            <p>Spots are limited to ensure quality.</p>
            
            <div className="animated cta-buttons-container">
              <a href={callendlyLink} target="_blank">
                <button className='animated'> Book a Call</button>
              </a>
              <Dialog
                  buttonName={"Share your vision"}
                />
              
            </div>
        </div>
    </>
  )
}

export default Cta