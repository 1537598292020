import React from "react";
import './footer.scss';

import bestcssBadge from '../../assets/images/bestcssBadge.svg';
import roco from '../../assets/images/roco.jpg';

const Footer = () => {
  return (
    <footer>
      <div id="roco-footer">
        <img id="roco-footer" src={roco} alt="" />
        <p>Roco Fernandez Pagella <br />
           Designer / Developer</p>
      </div>
      <div>
       
        <h3>About Me</h3>
        <p>
          I've spent the past 5 years working as a Consultant &
          Contractor, providing support to business owners across various
          sectors, from Corporate B2B Enterprises to Tech Startups. Through this
          journey, I've honed my skills in Branding, Design, UI/UX, SEO, Coding,
          and Marketing.
        </p>
        
        {/* <button>Learn More</button> */}

      </div>
      <div>

        <h3>The Mission</h3>
        <p>
          I firmly believe that empowering people who are driven by passion is
          the key to creating meaningful change in the world. When individuals
          are encouraged to pursue what truly inspires them, their energy and
          dedication can transform not only their own lives but also the lives
          of others.
        </p>
      </div>

      <div id="bestcss-footer">
        <img src={bestcssBadge} alt="Best Css badge - Site of the day - 10 november 2024" />
      </div>

      <p className="footer-data">
        ©2025 Website designed by Roco Fernández Pagella. All Rights Reserved.
        (v.1)
      </p>
    </footer>
  );
};

export default Footer;
